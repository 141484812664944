import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _, { filter } from 'lodash';
import {
  ADD_CLIENT,
  API_CLIENT_NAME,
  API_VARIABLE_USER_ID,
  VALUES_CLIENTS,
} from '../../api/api-constants';
import { AXIOS } from '../../api/axios';
import { InitializationError } from '../../common/errors';
import { DocumentEntity } from '../../common/types/EntityTypes';
import {
  mapDocumentEntityToOfflineEntity,
  mapOfflineDocumentEntityToDocumentEntity,
} from '../../common/types/Mapper';
import { DocumentsState } from '../../common/types/SliceTypes';
import {
  deleteDocuments,
  fetchOfflineDocumentsByClients,
  upsertDocuments,
} from '../../db/documentDBAction';
import { RootState } from '../store';
import { selectCurrentTab, selectSelectedDocumentId, setSelectedDocument } from './dashboardSlice';
import produce from 'immer';
import { selectParentsWithChildren } from './documentsSlice';
import { selectInboxId } from './metadataSlice';

const initialState = {} as DocumentsState;

export const documentsDataSlice = createSlice({
  name: 'documentsData',
  initialState: initialState,
  reducers: {
    addClientWithDocs: (state, action) => {
      if (action.payload) {
        const client = action.payload['client'];
        const docs = action.payload['docs'];

        if (client && docs) {
          return {
            ...state,
            [client]: docs,
          };
        }
      }
    },
    addDocument: (state, action) => {
      const document = action.payload;

      if (document) {
        const docClient = document['client'];
        if (docClient && docClient in state) {
          let docs = [...state[docClient]];
          docs.push(action.payload);
          return {
            ...state,
            [docClient]: docs,
          };
        } else {
          return {
            ...state,
            [docClient]: [action.payload],
          };
        }
      }
    },
    addDocumentsToFilterView: (state, action) => {
      const leaseDoc = action.payload['document'];
      const filterId = action.payload['filterId'];
      console.log('Adding Doc to filter  --' + filterId + '---' + ' -- docId--' + leaseDoc);
      if (leaseDoc) {
        let docs;
        if (filterId && filterId in state) {
          let docs = [leaseDoc];
          let filterDocs = state[filterId] || [];
          docs = [...docs, ...filterDocs];
        } else {
          docs = [leaseDoc];
        }
        return {
          ...state,
          [filterId]: docs,
        };
      }
    },
    updateParentForDocument: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const parentId = action.payload['parentId'];
      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.parent = parentId;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateParentForDocumentInbox: (state, action) => {
      const { documentId, parentId } = action.payload;
      const clientIDs = Object.keys(state);
      if (documentId) {
        clientIDs.forEach((clientId) => {
          state[clientId] = state[clientId].map((document) => {
            if (document.id === documentId) {
              const cDocument = { ...document, parent: parentId };
              return cDocument;
            }

            if (document.id === parentId) {
              const cDocument = { ...document, parent: '' };
              return cDocument;
            }

            return document;
          });
        });
      }
    },
    updateParentsForDocumentAllClients: (state, action) => {
      const { childrenIds, parentId } = action.payload;
      const clientIDs = Object.keys(state);
      if (childrenIds.length > 0) {
        childrenIds.forEach((documentId: string) => {
          clientIDs.forEach((clientId) => {
            state[clientId] = state[clientId].map((document) => {
              if (document.id === documentId) {
                const cDocument = { ...document, parent: parentId };
                console.log('Parenting Change to '+ parentId + ' --- ' + document.id);
                return cDocument;
              }

              if (document.id === parentId) {
                const cDocument = { ...document, parent: '' };
                console.log('Unparenting --- ' + document.id);
                return cDocument;
              }

              return document;
            });
          });
        });
      }
    },
    unParentingForDocumentAllClients: (state, action) => {
      const { childrenIds, parentId } = action.payload;
      const clientIDs = Object.keys(state);
      if (childrenIds.length > 0) {
        childrenIds.forEach((documentId: string) => {
          clientIDs.forEach((clientId) => {
            state[clientId] = state[clientId].map((document) => {
              if (document.id === documentId) {
                const cDocument = { ...document, parent: '' };
                console.log('Unparenting --- ' + document.id);
                return cDocument;
              }
              return document;
            });
          });
        });
      }
    },
    updateParentToNonParent: (state, action) => {
      const { documentId, isParent } = action.payload;
      const clientIDs = Object.keys(state);
      clientIDs.forEach((clientId) => {
        state[clientId] = state[clientId].map((document) => {
          if (document.parent === documentId) {
            const cDocument = { ...document, parent: '' };
            console.log('Unparenting --- ' + document.id);
            return cDocument;
          }
          return document;
        });
      });
    },
    setDocuments: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    markDocumentRead: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const status = action.payload['status'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.read = status;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    setDocumentRowAsExpanded: (state, action) => {
      const clientId = action.payload['client'];
      const documentId = action.payload['id'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.rowsExpanded = !cDocument.rowsExpanded;
              return cDocument;
            } else {
              const cDocument = { ...document };
              cDocument.rowsExpanded = false;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateNotesForDocument: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const notes = action.payload['notes'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.notes = notes;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateStatusForDocument: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const status = action.payload['status'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.status = status;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateMarketForDocument: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const marketExternalId = action.payload['marketExternalId'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.market = marketExternalId;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateAuthorForDocument: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const authorId = action.payload['authorId'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.author = authorId;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateFieldValueForDocument: produce((state, action) => {
      const documentId = action.payload['documentId'];
      const clientIDs = Object.keys(state);
      if (documentId) {
        clientIDs?.forEach((clientId) => {
          state[clientId] = state[clientId]?.map((document: DocumentEntity, index: number) => {
            if (document.id === documentId) {
              state[clientId][index].values = state[clientId][index].values?.map((field: any) => {
                if (field.field === action.payload['fieldCode']) {
                  const cField = {
                    ...field,
                    value: action.payload['newValue'],
                    prevValue: action.payload['oldValue'],
                  };
                  return cField;
                }
                return field;
              });
            }
            return document;
          });
        });
      }
    }),
    updateLeaseDocumentDeleted: (state, action) => {
      const documentId = action.payload['documentId'];
      const clientId = action.payload['clientId'];

      if (documentId) {
        const index = state[clientId].findIndex((s) => s.id == documentId);
        if (index >= 0) {
          return {
            ...state,
            [clientId]: [...state[clientId].slice(0, index), ...state[clientId].slice(index + 1)],
          };
        }
      }
    },
    removingDocumentFromFilter: (state, action) => {
      const documentId = action.payload['documentId'];
      const clientIDs = Object.keys(state);
      console.log('Removing Doc from filter  ' + clientIDs.length + ' -- docOId--' + documentId);
      if (documentId) {
        clientIDs.forEach((clientId) => {
          const index = state[clientId]?.findIndex((s) => s.id == documentId);
          if (index > -1) {
            state[clientId] = [
              ...state[clientId].slice(0, index),
              ...state[clientId].slice(index + 1),
            ];
          }
        });
      }
    },
    mapRowPin: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];
      const pinStatus = action.payload['status'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.locked = !pinStatus;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    toggleRowPin: (state, action) => {
      const clientId = action.payload['clientId'];
      const documentId = action.payload['documentId'];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.locked = !cDocument.locked;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(fetchUserClientsDocuments.fulfilled, fetchUserClientsDocuments.pending),
      (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      },
    );
  },
});

export const {
  addDocument,
  addClientWithDocs,
  setDocuments,
  toggleRowPin,
  updateParentForDocument,
  updateAuthorForDocument,
  setDocumentRowAsExpanded,
  updateMarketForDocument,
  updateStatusForDocument,
  updateNotesForDocument,
  markDocumentRead,
  mapRowPin,
  updateLeaseDocumentDeleted,
  addDocumentsToFilterView,
  removingDocumentFromFilter,
  updateFieldValueForDocument,
  updateParentForDocumentInbox,
  updateParentsForDocumentAllClients,
  updateParentToNonParent,
  unParentingForDocumentAllClients,
} = documentsDataSlice.actions;

export const selectRows = (state: RootState) => state.documentsData;

export const selectRowsForClients = createSelector(
  [selectRows, selectCurrentTab],
  (rows, clientId) => {
    if (Object.keys(rows).indexOf(clientId) < 0) {
      return [];
    } else {
      return rows[clientId];
    }
  },
);

export const findClientIdForDocumentId = createSelector(
  [selectRows],
  (rows) =>
    (documentId: string): string | undefined => {
      const clientIds = Object.keys(rows);
      for (const clientId of clientIds) {
        const documents = rows[clientId];
        const foundDoc = documents.find((doc) => doc.id === documentId);

        if (foundDoc) {
          return clientId;
        }
      }
      return undefined;
    },
);

export const selectChildrenForParent = createSelector(
  [selectRowsForClients, selectSelectedDocumentId],
  (rowsForClientId, documentId) => {
    return rowsForClientId && rowsForClientId.filter((row) => row.parent == documentId);
  },
);
0;
export const selectDocumentFromInbox = createSelector(
  [selectInboxId, selectRows],
  (inboxId, documents) =>
    (documentId: string): DocumentEntity[] => {
      return documents[inboxId]?.filter((e) => e.id === documentId);
    },
);

export const fetchUserClientsDocuments = createAsyncThunk(
  '/user/clients/documents',
  async (userData: { clientIds: string[]; userId: string }, { dispatch }) => {
    if (userData) {
      if (userData.clientIds && userData.clientIds.length > 0) {
        const url = VALUES_CLIENTS.replace(API_VARIABLE_USER_ID, userData.userId);

        let docs = {};

        const response = await AXIOS.post(url, userData.clientIds);

        if (response.status == 200) {
          const serverResponse = response.data.data;

          // docs = {
          //   ..._.groupBy(
          //     Object.values(serverResponse).map(
          //       (data) => data as DocumentEntity
          //     ),
          //     "client"
          //   ),
          // };
          return serverResponse;
        }

        // //Fetch the Offline Documents
        // let clientsDocsOffline = await fetchOfflineDocumentsByClients(
        //   userData.clientIds
        // );

        // // Transform to the respective format and group them
        // let clientDocuments = {
        //   ..._.groupBy(
        //     clientsDocsOffline.map((f) =>
        //       mapOfflineDocumentEntityToDocumentEntity(f)
        //     ),
        //     "client"
        //   ),
        // };

        // const url = VALUES_CLIENTS.replace(
        //   API_VARIABLE_USER_ID,
        //   userData.userId
        // );
        // const response = await AXIOS.post(url, userData.clientIds);

        // if (clientsDocsOffline == undefined || clientsDocsOffline.length == 0) {
        //   if (response.status == 200) {
        //     const data = response.data.data;

        //     const docs = Object.values(data)
        //       .map((data) => data as DocumentEntity)
        //       .flat()
        //       .map((doc) => mapDocumentEntityToOfflineEntity(doc, true));

        //     await upsertDocuments(docs);

        //     clientDocuments = { ...data };
        //   } else {
        //     throw new InitializationError("Client Documents not available.");
        //   }
        // } else {
        //   if (response.status == 200) {
        //     const serverResponse = response.data.data;

        //     // In future when we require to maintain custom author the non persisted changes have to be pushed back
        //     const nonPersistedDocs = clientsDocsOffline.filter(
        //       (doc) => !doc.isPersisted
        //     );

        //     // Add new Object
        //     const newClientsWithDocs = _.difference(
        //       Object.keys(serverResponse),
        //       Object.keys(clientDocuments)
        //     );

        //     newClientsWithDocs.forEach(async (client) => {
        //       const docs = serverResponse[client].map((doc: DocumentEntity) =>
        //         mapDocumentEntityToOfflineEntity(doc, true)
        //       );
        //       await upsertDocuments(docs);

        //       if (client && client in Object.keys(clientDocuments)) {
        //         clientDocuments[client] = [
        //           ...clientDocuments[client],
        //           ...serverResponse[client],
        //         ];
        //       } else {
        //         clientDocuments[client] = serverResponse[client];
        //       }
        //     });

        //     // Delete old Object
        //     const oldClientsWithDocsNotOnBackend = _.difference(
        //       Object.keys(clientDocuments),
        //       Object.keys(serverResponse)
        //     );

        //     oldClientsWithDocsNotOnBackend.forEach(async (client) => {
        //       await deleteDocuments(
        //         clientDocuments[client].map((doc) => doc.id)
        //       );

        //       delete clientDocuments[client];
        //     });

        //     const commonClients = _.intersection(
        //       Object.keys(serverResponse),
        //       Object.keys(clientDocuments)
        //     );

        //     // const newDocuments: DocumentEntity[] = [];
        //     // commonClients.forEach((client) => {
        //     //   console.log(
        //     //     _.differenceBy(
        //     //       serverResponse[client],
        //     //       clientDocuments[client],
        //     //       "id"
        //     //     )
        //     //   );
        //     // });
        //   }
        // }
        // return clientDocuments;
      }
    }
  },
);

export const updateClientForDocumentAPI = createAsyncThunk(
  'rows/client/update/',
  async (payload: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'rows/' +
      payload.documentId +
      '/client/' +
      payload.clientId +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.put(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const exportDocumentDataToExcel = createAsyncThunk(
  'rows/client/export/',
  async (payload: any, { getState }) => {
    const state = getState() as RootState;
    let url =
      process.env.REACT_APP_API_BASE_URL +
      'export/' +
      payload.filterId +
      '/' +
      state.user.externalId;
    try {
      const response = await AXIOS.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export default documentsDataSlice.reducer;
