import { useWindowSize } from "@react-hook/window-size/throttled";
import classNames from "classnames";
import _ from "lodash";
import { contextMenu } from "react-contexify";
import { EditText } from "react-edit-text";
import TextTruncate from "react-text-truncate";
import {
  AUTHOR_MENU_ID,
  CONTEXT_MENU_ID,
  CUSTOM_COLUMN_KEY,
  DEFAULT_AUTHOR_NAME,
  IMAGE_SUB_TYPE,
  IMAGE_TYPE,
  LEASE_STATUS,
  MARKET_MENU_ID,
  MENU_SUB_TYPE,
  STATUS_MENU_ID,
} from "../../../../common/constants";
import {
  DocumentEntity,
  DocumentFieldValuesEntity,
} from "../../../../common/types/EntityTypes";
import { CustomCellParams } from "../../../../common/types/dashboard/DashboardUITypes";
import { getImage, getImageSize } from "../../../../common/util";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  selectCurrentTab,
  selectSelectedDocumentExpandedId,
  selectSelectedDocumentId,
  setDocumentCollapsed,
  setDocumentExpanded,
  setSelectedDocument,
  updateRowChildrenViewStatus,
  updateRowPinStatus,
} from "../../../../redux/reducers/dashboardSlice";
import "../Table.scss";
import "../../../popup/lease-status/LeaseStatus.scss";
import {
  selectAuthors,
  selectMarkets,
} from "../../../../redux/reducers/metadataSlice";
import { selectMenu, setMenu } from "../../../../redux/reducers/popupSlice";
import {
  setDocumentNotes,
  setDocumentTradeArea,
} from "../../../../redux/thunks";
import {
  selectRows,
  toggleRowPin,
} from "../../../../redux/reducers/documentsDataSlice";
import LockPin from "../../../../assets/images/dashboard/table/pinned-row/LockPin.svg";
import LockedPin from "../../../../assets/images/dashboard/table/pinned-row/LockedPin.svg";
import EditableCellIndicator from "../../../../assets/images/dashboard/table/EditableCellIndicator.svg";
import {
  DType,
  selectedRelatedUnmatchedDocs,
  selectParentsWithChildren,
  selectRelatedUnmatchedDocuments,
} from "../../../../redux/reducers/documentsSlice";
import {
  selectUserActiveRowHeight,
  selectUserInactiveRowHeight,
} from "../../../../redux/reducers/settingsSlice";
import { selectReadOnlyApp } from "../../../../redux/reducers/appSlice";

function TableCell(props: CustomCellParams) {
  const [width, height] = useWindowSize();

  const dispatch = useAppDispatch();
  const isReadOnly = useAppSelector(selectReadOnlyApp);
  const authors = useAppSelector(selectAuthors);
  const markets = useAppSelector(selectMarkets);
  const selectedTab = useAppSelector(selectCurrentTab);
  const selectedRowId = useAppSelector(selectSelectedDocumentId);
  const selectedMenu = useAppSelector(selectMenu);
  const parentsWithChildren = useAppSelector(selectParentsWithChildren);
  const expandedDocumentId = useAppSelector(selectSelectedDocumentExpandedId);
  const userMaxNoLines = Number(
    useAppSelector(selectUserActiveRowHeight).value
  );
  const userMinNoLines = Number(
    useAppSelector(selectUserInactiveRowHeight).value
  );
  const selectedFilterData =
    useAppSelector(selectRows)[useAppSelector(selectCurrentTab)];
  const unMatchedRelatedDocIds = useAppSelector(selectedRelatedUnmatchedDocs);
  const unMatchedRelatedDocuments = useAppSelector(
    selectRelatedUnmatchedDocuments
  );

  function getAuthor(authorId: any) {
    let selectedAuthor = authors?.find(
      (author) => authorId == author.externalId
    );
    if (selectedAuthor == null || selectedAuthor == undefined) {
      selectedAuthor = authors?.find(
        (author) => author.name == DEFAULT_AUTHOR_NAME
      );
    }

    return selectedAuthor?.name;
  }

  function getMarkets(marketId: any) {
    let selectedMarket = markets?.find(
      (market) => marketId == market.externalId
    );

    if (selectedMarket == null || selectedMarket == undefined) {
      selectedMarket = markets?.find(
        (market) => market.name == DEFAULT_AUTHOR_NAME
      );
    }
    return selectedMarket?.name;
  }

  function displayMenu(
    e: React.MouseEvent<HTMLElement>,
    data: string,
    menuType: string
  ) {
    if (
      e.currentTarget.offsetParent != null &&
      e.currentTarget.offsetParent != undefined &&
      !isReadOnly
    ) {
      let parentDoc = e.currentTarget.offsetParent?.getBoundingClientRect();
      let xPos = parentDoc.left + parentDoc.width + 5;
      let yPos = parentDoc.top + 5;
      if (menuType == MENU_SUB_TYPE.STATUS) {
        dispatch(setMenu(STATUS_MENU_ID));
      } else if (menuType == MENU_SUB_TYPE.AUTHOR) {
        dispatch(setMenu(AUTHOR_MENU_ID));
      } else if (menuType == MENU_SUB_TYPE.MARKET) {
        dispatch(setMenu(MARKET_MENU_ID));
      } else if (menuType == MENU_SUB_TYPE.CONTEXT_MENU) {
        dispatch(setMenu(CONTEXT_MENU_ID));
        xPos = e.clientX + 5;
        yPos = e.clientY + 5;
      }

      contextMenu.show({
        id: selectedMenu,
        event: e,
        props: { data },
        position: {
          x: xPos,
          y: menuType == MENU_SUB_TYPE.MARKET ? yPos : e.clientY - 10,
        },
      });
    }
  }

  function handleNotes(input: {
    name: string;
    value: string;
    previousValue: string;
  }): void {
    if (input.value !== input.previousValue) {
      dispatch(
        setDocumentNotes({
          id: props.data.id,
          notes: input.value,
        })
      );
    }
  }

  function handleTrade(input: {
    name: string;
    value: string;
    previousValue: string;
  }): void {
    if (input.value !== input.previousValue) {
      dispatch(
        setDocumentTradeArea({
          id: props.data.id,
          trade: input.value,
        })
      );
    }
  }

  function handleRowPin(event: React.MouseEvent<HTMLImageElement>) {
    dispatch(setSelectedDocument(props.data));

    // if (!props.data.locked) {
    //   dispatch(setSelectedDocument(props.data));
    // } else {
    //   if (selectedDocumentId == props.data.id) {
    //     dispatch(setDeSelectDocument());
    //   }
    // }
    // Update the Redux State
    dispatch(
      toggleRowPin({
        clientId: selectedTab,
        documentId: props.data.id,
      })
    );
    dispatch(updateRowPinStatus(!props.data.locked));
    // Commenting out to deflickering
    // dispatch(setDeSelectDocument());
  }

  function handleChildren(
    event: React.MouseEvent<HTMLDivElement>,
    isChildExists: Boolean,
    isExpanded: Boolean
  ) {
    if (!isChildExists) {
      return;
    }
    // Removing Shade from previous expanded documents
    if (expandedDocumentId) {
      const prevSelectedDoc = document.querySelectorAll(`[row-id="${expandedDocumentId[0]}"]`);
      prevSelectedDoc && prevSelectedDoc.forEach((el) => {
        el.nextElementSibling?.classList.remove("neighbour-row-expanded");
        el.previousElementSibling?.classList.remove("parent-row-expanded");
        el.classList.remove("row-child-lease");
      });
    }

    // Removing Highlight from Parent

    if (props.data.id) {
      const prevSelectedDoc = document.querySelectorAll(`.selected-row-color`);
      prevSelectedDoc && prevSelectedDoc.forEach((el) => {
        // el.classList.remove("selected-row-color");
        el.classList.remove("row-child-lease");
      });
    }
    
    const parentEl = document.querySelectorAll(`[row-id="${props.data.id}"]`);
    if (isExpanded) {
      parentEl &&
        parentEl.forEach((el) => {
          el.nextElementSibling?.classList.remove("neighbour-row-expanded");
          el.previousElementSibling?.classList.remove("parent-row-expanded");
          el.classList.remove("row-child-lease");
        });
      dispatch(setDocumentCollapsed(props.data.id));
    } else {
      parentEl &&
        parentEl.forEach((el) => {
          el.previousElementSibling?.classList.add("parent-row-expanded");
          el.nextElementSibling?.classList.add("neighbour-row-expanded");
          // el.classList.add("parent-row-expanded");
          el.classList.add("row-child-lease");
        });
      dispatch(setDocumentExpanded(props.data.id));
    }
    dispatch(updateRowChildrenViewStatus(!props.data.expanded));
  }

  const getCSSForLeaseStatus = (
    name: string,
    isParentChildValueDiffers?: boolean
  ) => {
    let cssClasses = "";
    if (name == LEASE_STATUS.DEAD_DEAL) {
      cssClasses = "tc-dead-deal";
    } else if (name == LEASE_STATUS.IN_LEASE) {
      cssClasses = "tc-cell-in-lease";
    } else {
      cssClasses = "tc-active-lease";
    }

    if (isParentChildValueDiffers) {
      cssClasses = cssClasses + " parent-child-value-differs ";
    }

    if (
      unMatchedRelatedDocIds.indexOf(props.data.id) > -1 &&
      !isParentChildValueDiffers
    ) {
      cssClasses = cssClasses + " unmatched-document-values ";
    }
    return cssClasses;
  };

  function getData() {
    const headerKey = props.customData?.code;
    const status = props.data.status;
    let maxNoOfLines = userMaxNoLines,
      minNoOfLines = userMinNoLines;

    if (props.data.status == LEASE_STATUS.DUMMY) {
      return "";
    }

    if (headerKey == CUSTOM_COLUMN_KEY.PIN) {
      const isLocked = props.data.locked;

      let src = "";
      if (isLocked) {
        src = LockedPin;
      } else if (props.data.id === selectedRowId) {
        src = LockPin;
      }
      const dim = getImageSize(width, height, IMAGE_TYPE.ROW_PIN);
      // const src = getImage(
      //   width,
      //   height,
      //   IMAGE_TYPE.ROW_PIN,
      //   isLocked ? IMAGE_SUB_TYPE.ROW_UNPINNED : IMAGE_SUB_TYPE.ROW_PINNED
      // );
      return (
        <div
          className={classNames(
            isReadOnly ? "visible-hidden" : "visibilty-not-hiddent",
            "flex  fit-container flex-align-center"
          )}
        >
          <img
            // width={dim.width}
            // height={dim.height}
            src={src}
            onClick={(event) => handleRowPin(event)}
          ></img>
        </div>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.STATUS) {
      const dim = getImageSize(width, height, IMAGE_TYPE.STATUS);
      const state = props.data.status;
      let imgSrc = getImage(width, height, IMAGE_TYPE.STATUS, state);
      let tradeArea =
        props.data.trade == "Enter Trade Area" ? "" : props.data.trade;
      const author = getAuthor(props.data.author);
      return (
        <section
          className={classNames(
            "flex",
            "combined-status-col",
            getCSSForLeaseStatus(status)
          )}
        >
          <div className="status-label">LOI State</div>
          <div
            className={classNames(
              "flex",
              "fit-container",
              "ag-cell-font-common",
              "editable-cell",
              "loi-status-font-weight-parent",
              // "bold-text",
              "text-read",
              getCSSForLeaseStatus(status)
            )}
            onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.STATUS)}
          >
            <div>{_.startCase(_.lowerCase(_.replace(state, "_", " ")))}</div>
            <div
              className={classNames(
                "indicator",
                isReadOnly ? "visible-hidden" : "visibilty-not-hiddent"
              )}
            >
              <img src={EditableCellIndicator} alt="&gt;" />
            </div>
            {/* <img width={dim.width} height={dim.height} src={imgSrc}></img> */}
          </div>
          <div className="status-label">Author</div>
          <div
            onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.AUTHOR)}
            className={classNames(
              "fit",
              "fit-container",
              "editable-cell",
              "text-read",
              getCSSForLeaseStatus(status)
            )}
          >
            <div>{author}</div>
            <div
              className={classNames(
                isReadOnly ? "visible-hidden" : "visibilty-not-hiddent",
                "indicator"
              )}
            >
              <img src={EditableCellIndicator} alt="&gt;" />
            </div>
          </div>
          <div className="status-label">Market</div>
          <div
            className={classNames(
              "fit",
              "fit-container",
              "editable-cell",
              props.data.read ? "text-read" : "text-unread",
              "ag-cell-font-common",
              getCSSForLeaseStatus(status)
            )}
            onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.MARKET)}
          >
            <div>{getMarkets(props.data.market) || "None"}</div>
            <div
              className={classNames(
                isReadOnly ? "visible-hidden" : "visibilty-not-hiddent",
                "indicator"
              )}
            >
              <img src={EditableCellIndicator} alt="&gt;" />
            </div>
          </div>
          <div className="status-label">Trade Area</div>
          {
            <EditText
              inputClassName={classNames(
                "ag-cell-font-common",
                "ta-text-area",
                "ta-input",
                "text-read"
              )}
              className={classNames(
                "text-read",
                "ta-input-label",
                getCSSForLeaseStatus(status)
              )}
              placeholder={
                tradeArea !== "" ? props.data.trade : "Enter Trade Area"
              }
              // rows={selectedRowId == props.data.id ? maxNoOfLines : minNoOfLines}
              defaultValue={tradeArea}
              onSave={handleTrade}
              readonly={isReadOnly}
            ></EditText>
          }
        </section>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.NOTES) {
      const notes =
        props.data.notes == ""
          ? "Enter Deal Notes and Next Steps"
          : props.data.notes;

      return isReadOnly ? props.data.notes : (
        <EditText
          inputClassName={classNames(
            "ag-cell-font-common",
            "ta-text-area",
            "ta-input",
            props.data.read ? "text-read" : "text-unread"
          )}
          placeholder={notes}
          className={classNames(
            props.data.read ? "text-read" : "text-unread",
            "ta-input-label",
            getCSSForLeaseStatus(status)
          )}
          defaultValue={props.data.notes}
          onSave={handleNotes}
        ></EditText>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.DOCUMENT_NAME) {
      const docName = props.data.name;

      return (
        <TextTruncate
          containerClassName={classNames(
            "ag-cell-font-common",
            props.data.read ? "text-read" : "text-unread",
            getCSSForLeaseStatus(status)
          )}
          line={selectedRowId == props.data.id ? maxNoOfLines : minNoOfLines}
          textElement="div"
          text={docName}
        />
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.CHILDREN) {
      const expanded = props.data.expanded;
      const dim = getImageSize(width, height, IMAGE_TYPE.CHILDREN);
      const isChildExists =
        parentsWithChildren.includes(props.data.id) && props.data.parent === "";
      const isExpanded = expandedDocumentId.includes(props.data.id);
      return (
        <div
          className="flex  fit-container flex-align-center cursor-pointer"
          onClick={(event) => handleChildren(event, isChildExists, isExpanded)}
        >
          <img
            className={classNames(
              { "visibilty-not-hidden": isChildExists },
              { "visible-hidden": !isChildExists }
            )}
            width={dim.width}
            height={dim.height}
            src={getImage(
              width,
              height,
              IMAGE_TYPE.CHILDREN,
              isExpanded ? IMAGE_SUB_TYPE.HIDE : IMAGE_SUB_TYPE.SHOW
            )}
          ></img>
        </div>
      );
    }
    const column: DocumentFieldValuesEntity = props.data.values.find(
      (d: DocumentFieldValuesEntity) => d.field == props.customData?.code
    );
    if (column != undefined) {
      let data = column.value;
      if (data == "---") {
        data = "-";
      }
      if (props.customData?.code == "003m") {
        return (
          <div
            className={classNames(
              "ag-cell-font-common",
              props.data.read ? "text-read" : "text-unread",
              getCSSForLeaseStatus(status)
            )}
          >
            {data}
          </div>
        );
      }
      const isParentChildValueDiffers = checkWhetherChildValueDiffer(
        column,
        props.data.id,
        props.data.parent
      );
      return (
        <TextTruncate
          containerClassName={classNames(
            "ag-cell-font-common",
            props.data.read ? "text-read" : "text-unread",
            getCSSForLeaseStatus(status, isParentChildValueDiffers)
          )}
          line={selectedRowId == props.data.id ? maxNoOfLines : minNoOfLines}
          textElement="div"
          text={data}
        />
      );
    }

    return "";
  }

  const checkWhetherChildValueDiffer = (
    currentField: DocumentFieldValuesEntity,
    docId: string,
    parentId: string
  ) => {
    if (selectedFilterData?.length > 0) {
      let filterDateWithRelatedUnmatchedDocs: DocumentEntity[] = [
        ...selectedFilterData,
      ];
      if (unMatchedRelatedDocuments?.length > 0) {
        filterDateWithRelatedUnmatchedDocs = [
          ...filterDateWithRelatedUnmatchedDocs,
          ...unMatchedRelatedDocuments,
        ];
      }
      if (parentId === "") {
        if (parentsWithChildren.includes(docId)) {
          const childrensList = filterDateWithRelatedUnmatchedDocs
            ?.filter((el) => el.parent === docId)
            .sort(
              (a, b) => new Date(b.ts).getTime() - new Date(a.ts).getTime()
            );
          const childrenField = childrensList?.[0]?.values.filter(
            (el) => el.field === currentField.field
          )[0];
          if (
            childrenField?.value !== undefined &&
            currentField?.value !== undefined
          ) {
            return (
              childrenField?.value?.replace(/[\s\t]/g, "").trim() !==
                currentField?.value?.replace(/[\s\t]/g, "").trim() &&
              expandedDocumentId?.includes(docId)
            );
          } else {
            return false;
          }
        }
        return false;
      }
      const sibilingsList = filterDateWithRelatedUnmatchedDocs
        ?.filter((el) => el.parent === parentId)
        .sort((a, b) => new Date(b.ts).getTime() - new Date(a.ts).getTime());
      const currentChildIndex = sibilingsList?.findIndex(
        (el) => el.id === docId
      );
      if (currentChildIndex === sibilingsList?.length - 1) {
        return false;
      } else {
        const youngerSibling = sibilingsList?.[
          currentChildIndex + 1
        ]?.values?.filter((el) => el.field === currentField.field);
        if (
          youngerSibling?.[0]?.value !== undefined &&
          currentField?.value !== undefined
        ) {
          return (
            youngerSibling?.[0]?.value.replace(/[\s\t]/g, "").trim() !==
            currentField?.value.replace(/[\s\t]/g, "").trim()
          );
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    displayMenu(e, props.data, MENU_SUB_TYPE.CONTEXT_MENU);
  };
  return (
    <div
      style={{ width: "100%", whiteSpace: "normal", overflow: "visible" }}
      onContextMenu={handleContextMenu}
    >
      {getData()}
    </div>
  );
}

export default TableCell;
