import {
  CellClassParams,
  ColDef,
  IRowNode,
  GetRowIdFunc,
  GetRowIdParams,
  ModelUpdatedEvent,
  CellClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useCallback, useMemo, useState, useRef } from "react";
import Toggle from "react-toggle";
import {
  AP_STATUS,
  BACKEND_AP_STATUS,
  UPLOAD_HEADER,
} from "../../../common/constants";
import { ImageMetadata } from "../../../common/types/dashboard/DashboardUITypes";
import { DocumentsStatusState } from "../../../common/types/SliceTypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectDocumentsStatus } from "../../../redux/reducers/documentsStatusSlice";
import { setClose } from "../../../redux/reducers/popupSlice";
import {
  selectResolutionMetadata,
  selectUploadDialogLogoMetadata,
} from "../../../redux/reducers/uiSlice";
import UploadTableCell from "./upload-table-cell/UploadTableCell";
import UploadTableHeader from "./upload-table-header/UploadTableHeader";
import "./upload.scss";
import { getUpdatedCustomFontSize } from '../../../common/util';
import { selectUserTableFontSize } from '../../../redux/reducers/settingsSlice';
import { setDocumentExpanded, setSelectedDocument, updateRowChildrenViewStatus } from '../../../redux/reducers/dashboardSlice';
import { selectRows } from '../../../redux/reducers/documentsDataSlice';
import { selectInboxId } from '../../../redux/reducers/metadataSlice';
function Upload() {
  const dispatch = useAppDispatch();
  const userTableFontSize = Number(useAppSelector(selectUserTableFontSize).value);
  const containerStyle = {
    width: "100%",
    margin: "var(--custom-upload-grid-margin)",
    marginRight: '10px',
    display: "flex",
    "flex-direction": "column",
    gap: "var(--custom-upload-dialog-header-content-gap)",
    overflow: "hidden",
    '--custom-font-size': userTableFontSize + 'px',
    '--custom-upload-dialog-header-filter-text-font-size': getUpdatedCustomFontSize('--custom-upload-dialog-header-filter-text-font-size'),
    '--custom-close-font-size': getUpdatedCustomFontSize('--custom-close-font-size'),
    '--custom-close-width': getUpdatedCustomFontSize('--custom-close-width'),
  };
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  let docsList = useAppSelector(selectDocumentsStatus);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const logo: ImageMetadata = useAppSelector(selectUploadDialogLogoMetadata);
  const gridRef = useRef<AgGridReact<DocumentsStatusState>>(null);
  const inboxDocuments = useAppSelector(selectRows)[useAppSelector(selectInboxId)];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
    };
  }, []);

  const components = useMemo(
    () => ({
      agColumnHeader: UploadTableHeader,
    }),
    []
  );

  const [filters, setFilters] = useState([
    {
      name: AP_STATUS.DONE,
      text: _.capitalize(AP_STATUS.DONE),
      selected: true,
    },
    {
      name: AP_STATUS.IN_PROGRESS,
      text: _.startCase(_.toLower(_.replace(AP_STATUS.IN_PROGRESS, "_", " "))),
      selected: true,
    },    {
      name: AP_STATUS.ERROR,
      text: _.capitalize(AP_STATUS.ERROR),
      selected: true,
    },
  ]);

  function getCellStyle(params: CellClassParams<any, any>, type: string) {
    if (params.data.apStatus == 'ERROR' || params.data.apStatus == 'AP_ERROR') {
      return { background: "#fa977f" }
    } 
    if (params.data.percentageCompleted == 100) {
      if (!params.data.read) return { background: "rgba(250, 212, 127, 1)" };
      else return { background: "rgba(250, 212, 127, 1)" };
    } else {
      if (
        type == UPLOAD_HEADER.DOCUMENT_NAME &&
        params.data.percentageCompleted > 0
      ) {
        return {
          background:
            "linear-gradient(to right, rgba(250, 212, 127, 1) " +
            params.data.percentageCompleted +
            "%, white " +
            (params.data.percentageCompleted) +
            "%)",
        };
      }
      return null;
    }
  }

  function headersDef(): ColDef[] {
    let colDef: ColDef[] = [
      {
        colId: UPLOAD_HEADER.STATUS,
        headerComponentParams: { type: UPLOAD_HEADER.STATUS },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.STATUS },
        // flex: 0.6,
        cellStyle: (params) => getCellStyle(params, UPLOAD_HEADER.STATUS),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = nodeA.data.apStatus;
          const nodeBData = nodeB.data.apStatus;
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_STATUS.widthPX,
      },
      {
        colId: UPLOAD_HEADER.DOCUMENT_NAME,
        headerComponentParams: { type: UPLOAD_HEADER.DOCUMENT_NAME },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.DOCUMENT_NAME },
        // flex: 2,
        cellStyle: (params) =>
          getCellStyle(params, UPLOAD_HEADER.DOCUMENT_NAME),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = nodeA.data.fileName;
          const nodeBData = nodeB.data.fileName;
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_DOCUMENT_NAME.widthPX,
      },
      {
        colId: UPLOAD_HEADER.UPLOADED,
        headerComponentParams: { type: UPLOAD_HEADER.UPLOADED },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.UPLOADED },
        flex: 0.5,
        cellStyle: (params) => getCellStyle(params, UPLOAD_HEADER.UPLOADED),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = new Date(nodeA.data.lastActionAt);
          const nodeBData = new Date(nodeB.data.lastActionAt);
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_DOCUMENT_NAME.widthPX,
        initialSort: 'desc',
      },
    ];

    return colDef;
  }

  const toggleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterState = [...filters];
    filterState.forEach((filter) => {
      if (filter.name == e.currentTarget.value) {
        filter.selected = !filter.selected;
      }
    });
    setFilters(filterState);
    // updateFilteredDocs();
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<DocumentsStatusState>) => params.data.id;
  }, []);

  const customBtnStyle = {
    justifyContent: 'center',
    marginRight: '2rem',
    '--custom-close-font-size': getUpdatedCustomFontSize('--custom-close-font-size'),
    '--custom-upload-dialog-header-filter-text-font-size': getUpdatedCustomFontSize('--custom-upload-dialog-header-filter-text-font-size'),
  };

  const filtersDiv = filters.map((filter, index) => {
    return (
      <div
        key={index}
        className="flex"
        style={{
          gap: "var(--custom-upload-dialog-header-filter-text-gap)",
          alignItems: "center",
          height: "var(--custom-upload-dialog-header-height)",
        }}
      >
        <span
          className="flex filter-text"
          style={{
            fontSize:
              "var(--custom-upload-dialog-header-filter-text-font-size)",
            height: "var(--custom-upload-dialog-header-height)",
          }}
        >
          {filter["text"]}
        </span>
        <label
          style={{
            width: "var(--custom-upload-dialog-header-filter-toggle-width)",
            height: "var(--custom-upload-dialog-header-height)",
            alignItems: 'center'
          }}
          className="flex"
        >
          <Toggle
            defaultChecked={true}
            value={filter["name"]}
            icons={false}
            onChange={toggleSelection}
          />
        </label>
      </div>
    );
  });

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const handleCellClick = (event: CellClickedEvent) => {
    const selectedDocumentData = inboxDocuments.filter(e => e?.id === event.data?.id)[0];
    if (selectedDocumentData?.id === undefined) {
      return;
    }
    if (selectedDocumentData?.parent !== '') {
      dispatch(setDocumentExpanded(selectedDocumentData?.parent));
      dispatch(updateRowChildrenViewStatus(true));
    }
    dispatch(setSelectedDocument(event.data));
    setTimeout(() => {
      dispatch(setSelectedDocument({}));
    }, 100);
  };

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<DocumentsStatusState>) => {
      if (node.data) {
        const status = node.data.apStatus;
        const appliedFilters = filters
          .filter((filter) => filter.selected)
          .map((filter) => filter.name);
        if (
          status == BACKEND_AP_STATUS.AP_COMPLETE.toString() &&
          appliedFilters.includes(AP_STATUS.DONE)
        ) {
          return true;
        } else if (
          [
            BACKEND_AP_STATUS.UPLOADING.toString(),
            BACKEND_AP_STATUS.ABSTRACTING.toString(),
            BACKEND_AP_STATUS.CONVERTING.toString(),
            BACKEND_AP_STATUS.CONVERTING.toString(),
            BACKEND_AP_STATUS.UPLOADED.toString(),
            BACKEND_AP_STATUS.HIGHLIGHTING.toString()
          ].includes(status) &&
          appliedFilters.includes(AP_STATUS.IN_PROGRESS)
        ) {
          return true;
        }else if(status == BACKEND_AP_STATUS.ERROR.toString() &&
        appliedFilters.includes(AP_STATUS.ERROR)){
          return true;
        }
        return false;
      }
      return false;
    },
    [filters]
  );

  return (
    <>
      <div style={containerStyle}>
        <div
          className="flex"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "var(--custom-upload-dialog-header-height)",
          }}
        >
          <div
            className="flex"
            style={{ gap: "var(--custom-upload-dialog-header-filter-gap)" }}
          >
            {filtersDiv}
          </div>
          <img className="upload-logo cursor-default" src={logo.image}></img>
        </div>
        <div
          id="upload-grid"
          style={{
            height: "100%", boxSizing: "border-box",
            fontSize: userTableFontSize + 'px'
           }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<DocumentsStatusState>
              ref={gridRef}
              rowData={docsList}
              getRowId={getRowId}
              columnDefs={headersDef()}
              defaultColDef={defaultColDef}
              components={components}
              headerHeight={resMetadata.uploaderHeaderRowHeight}
              rowHeight={resMetadata.uploaderRowHeight}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              animateRows={true}
              onCellClicked={handleCellClick}
              suppressRowClickSelection={true}
              suppressCellFocus={true}
              overlayNoRowsTemplate="No Documents Uploaded"
              overlayLoadingTemplate="No Documents Uploaded"
              onModelUpdated={(event: ModelUpdatedEvent) => {
                  event.api.getDisplayedRowCount() === 0 ? 
                    event.api.showNoRowsOverlay() : 
                    event.api.hideOverlay();
              }}
            ></AgGridReact>
          </div>
        </div>
        <div className="flex flex-align-end">
          <button
            style={customBtnStyle}
            onClick={() => dispatch(setClose())}
            className="close-button close-btn-orange"
          >
            <span className="close-button-text">Close</span>
          </button>
        </div>
      </div>
    </>
  );
}
export default Upload;
